.App {
  background-color: #F4F4F4;
  width: 100vw;
  height: 100vh;
}

header {
  width:100%;
  height: 8.5rem;
}

main {
  margin: 0;
  font-family: 'Roboto Condensed', 'Helvetica Neue', 'Arial', sans-serif !important;
  height: calc(100% - 11rem);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

footer {
  width: 100%;
  height: 2.5rem;
}

.body {
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: var(--bkgd-color);
}
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-group-text {
  background-color: var(--bkgd-color);
}

input[type='submit'] {
  background-color: var(--primary);
  color: var(--text)
}

input[type='submit']:enabled:hover {
  background-color: var(--primary-active);
}

.submit {
  background-color: var(--primary);
  color: var(--text);
  width: 100%;
  border: 0;
}

.submit:hover {
  background-color: var(--primary-active);
}

.plus {
  flex: 1 1 auto;
  line-height: 7.5rem;
  font-size: 10rem;
}

.add-text {
  flex: 1 1 auto;
  line-height: 1.5em;
  font-size: 1.5em;
  color: var(--primary);
}

.pill {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: var(--bs-border-radius-pill);
}

.string-input {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
}

.string-input label {
  margin-bottom: 0px;
}

.string-input input {
  background: none;
  border: 0px;
  border-bottom: 1px solid var(--primary-soft);
  border-radius: 0;
  margin: 0 0.5em 0 0.5em;
  padding: 2px 5px;
  height: 1.5em;
  width: 95%;
}

.string-input input:focus {
  background: none;
  box-shadow: none;
}

.number-input label {
  margin-bottom: 0px;
}

.number-input input {
  font-size: 1.2em;
  border-radius: 0.2em !important;
  margin: 0px 5px;
  padding: 0px 0px 0px 5px!important;
  height: 1.5em;
  min-width: 2em;
  text-align: center;
}

.export {
  margin-right:0.5em;
  margin-left: auto;
  flex: 0 1 auto;
  justify-self: right;
  width: fit-content;
  background-color: var(--primary);
  color: var(--text);
  border: 0;
}

.left-caret {
  height: 1em;
  margin-left: -0.2em;
  margin-top: -0.2em;
  margin-right: 0.2em;
  transform: rotate(90deg);
}