.content-area {
    display: flex;
    flex-flow: row;
    height: 100%;
}
  
  
.main-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
}
  
.activity-card-group {
    text-align: center;
    height: fit-content !important;
    flex: 0 0 auto;
    border: 0px !important;
    border-radius: 50px !important;
    background-color: var(--primary-active) !important;
}
  
.activity-card-group .card-title {
    font-size: 1.5em;
    font-weight: 600;
    color: var(--text);
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 0px;
}
  
.activity-card-group>.card-body {
    padding: 10px;
    padding-top: 0px;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}
  
.activity-card-group .activity-card {
    margin: 0px;
}
  
.activity-card {
    text-align: center;
    min-width: 250px;
    height: min-content !important;
    flex: 0 0 auto;
    border: 0px !important;
    border-radius: 40px !important;
    background-color: var(--primary) !important;
}
  
.activity-card .card-title {
    font-size: 1.5em;
    font-weight: 600;
    color: var(--text);
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 0px;
}
  
.activity-card .card-body {
    padding: 10px;
    padding-top: 0;
    padding-bottom: 8px;
    display: flex;
    flex-flow: column;
}
  
.activity-card ul {
    height: 100px;
    border-radius: 10px;
}
  
.activity-card li:first-child {
    border-top-left-radius: inherit !important;
    border-bottom-left-radius: inherit !important;
}
  
.activity-card li:last-child {
    border-top-right-radius: inherit !important;
    border-bottom-right-radius: inherit !important;
}
  
.activity-card li {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    padding-bottom: 5px;
    background-color: var(--bkgd-color);
}
  
.activity-notes .badge {
    font-size: 1em;
    background-color: var(--primary) !important;
    transition: background-color 0.15s;
}
  
.activity-notes .badge:hover {
    background-color: var(--primary-active) !important;
}
  
.activity-number {
    display: flex;
    flex-flow: row;
    flex: 1 1 auto;
    font-size: 1.75em;
    font-weight: 600;
    line-height: 100%;
    align-items: center;
    justify-content: center;
}
  
.activity-number.primary-metric {
    font-size: 2.25em;
    color: var(--accent) !important;
}
  
.number-input-wrapper {
    width: min-content;
}
  
.activity-number input {
    padding: 0px;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    width: 300px;
    text-align: center;
    border: none;
    margin: auto;
    translate: 0.1em;
    background-color: var(--bkgd-color);
}
  
.activity-name {
    height: fit-content;
    line-height: 1em;
}
  
.activity-notes {
    margin-top: 8px;
    text-align: left;
    padding-left: 10px;
    font-weight: 600;
    font-size: 1.2em;
    color: var(--text);
    cursor: pointer;
}
  
.activity-card textarea {
    margin-bottom: 10px;
    border-radius: 20px;
}

.content-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    padding: 20px;
    gap: 20px;
}
  
.subtype-notes {
    text-align: left;
}
  
.subtype-notes span {
    color: var(--text);
    margin-left: 10px;
    margin-bottom: 5px;
    font-size: 1em;
    font-weight: 600;
    line-height: 1em;
}
  
.subtype-notes:first-child {
    margin-top: 5px;
}
  
.add-card {
    width:180px !important;
    height:180px !important;
    margin:11px;
    background-color: var(--bkgd-color) !important;
    border: 2px solid var(--primary) !important;
    border-radius: 40px !important;
    transition: 0.1s;
    text-align: center;
    flex: 0 0 auto;
    color: var(--primary) !important;
}

.add-card:hover {
    color: var(--primary-active) !important;
    background-color: var(--primary-tint) !important;
    border: 2px solid var(--primary-active) !important;
    cursor: pointer;
}
  
.add-card-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    color: inherit;
    transition: 0.1s;
}

#no-content {
    margin: auto;
    display: flex;
    flex-flow: column;
    text-align: center;
    font-size: 2.5em;
    color: var(--primary-soft)
}

#trees {
    max-width: 20%;
    margin: auto;
}

/* Dashboard */

#dashboard {
    display: flex;
    flex-flow: row;
}

#dashboard-tables {
    width: 80%;
    display: flex;
    flex-flow: column;
    gap: 20px;
}

#summary-sidebar {
    position: sticky;
    top: 0;
    flex: 1 1 auto;
    width: 20%;
    display: flex;
    flex-flow: column;
    gap: 10px;
}

#report-submit {
    width: 100%;
}

#report-submit button {
    background-color: var(--accent);
    border: 0;
    font-size: 1.2em;
    line-height: 3em;
    width: 100%;
}

#report-submit button:hover {
    background-color: var(--accent-active);
}

#report-submit button:disabled {
    background-color: gray;
}

#report-submit-confirmation-modal {
    max-width: 400px;
    margin-top: 300px;
}

#report-submit-confirmation-modal .confirmation-text {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
}

#report-submit-confirmation-modal .confirmation-text p {
    margin: 0;
}

#report-submit-confirmation-modal .submit {
    background-color: var(--accent);
}

#report-submit-confirmation-modal .submit:hover {
    background-color: var(--accent-active);
}

#contact-card {
    background-color: white;
    padding: 10px;
    border: 1px solid var(--primary-tint);
    border-radius: 1em;
    flex: 0 1 auto;
}

#contact-card span {
    margin-left: 5px;
}

#report-notes-card {
    background-color: white;
    padding: 10px;
    border: 1px solid var(--primary-tint);
    border-radius: 1em;
    max-height: 60%;
    max-width: 100%;
    display: flex;
    flex-flow: column;
}

#report-notes {
    max-height: 100%;
    width: 100%;
    overflow: auto;
}

#summary-view {
    display: flex;
    flex-flow: column;
    flex: 1 0 auto;
    height: 70%;
    border-radius: 0.5em;
    border-bottom-right-radius: 0;
    border: 1px solid var(--dark-gray);
    background-color: white;
}

.table-title {
    background-color: var(--light-black);
    display: flex;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-top-left-radius: calc(0.5em - 1px);
    border-top-right-radius: calc(0.5em - 1px);
}

.table-title>span {
    flex: 1 1 auto;
    font-size: 1.2em;
    color: var(--text);
    padding-left: 0.5em;
    padding-top: 0.5em;
    font-weight: 600;
}

#summary-table {
    flex: 1 1 auto;
    overflow-y: scroll;
    scroll-behavior: smooth;
    height: fit-content;
    border-bottom-left-radius: calc(0.5em - 1px);
    border-bottom-right-radius: calc(0.5em - 1px);
}

#summary-table td:not(:last-child) {
    border-right: 1px solid var(--bs-table-border-color);
}

#summary-table table {
    margin:0;
}

#summary-table th {
    background-color: var(--secondary);
}

#summary-table th:first-child {
    width: 30%;
}

#summary-table #era-subtable th:first-child {
    width: 60%;
}

#well-lists {
    flex: 1 1 auto;
    display:flex;
    flex-flow: row;
    max-width: 100%;
    max-height: 50%;
    gap: 1em;
}

.card-title-wrapper {
    display: flex;
    flex-flow: row;
}

.card-title-text {
    flex: 1 1 auto;
    margin-left: 0.8em;
}

.delete-button {
    position: relative;
    cursor: pointer;
}

.delete-button img {
    margin-top: -0.2em;
    height: 0.8em;
}

/* Drilling Page */


/* Wells Page */

#wells-page .add-card {
    margin-top: 45px;
}
  

/* Well Pads Page */

#well-pads-page .add-card {
    margin-top: 45px;
}

.activity-name .delete-button {
    display: inline;
    margin-left: 0.2em;
}

/* ERA Page */

#era-page {
    flex-flow: column;
    padding: 20px;
    gap: 20px;
}
  
.era-activity-card {
    text-align: center;
    min-width: 250px;
    width: fit-content;
    height: min-content !important;
    flex: 0 0 auto;
    border: 0px !important;
    border-radius: 40px !important;
    background-color: var(--primary) !important;
}
  
.era-activity-card textarea {
    margin-bottom: 10px;
    border-radius: 20px;
}
  
.era-activity-card .maintext {
    width: 50vw;
    height: 4em;
    resize: none;
}

#era-page .add-card {
    width: 50vw !important;
    height: 6em !important;
}
  
#era-page .add-card-body {
    flex-flow: row;
    align-content: middle;
    justify-items: center;
}
  
#era-page .plus {
    font-size: 7em;
    line-height: 4rem;
    width: fit-content;
    flex: 0 1 auto;
}
  
#era-page .add-text {
    display: flex;
    flex: 0 1 auto;
    line-height: 2em;
    align-items: center;
    padding-left: 0.5em;
}

#era-page .delete-button{
    display: inline;
    float: right;
    margin-right: 0.5em;
}