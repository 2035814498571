#public-view {
    height: 100%;
    display: flex;
    flex-flow: column;
}

#public-view label {
    font-weight: 600;
    line-height: 1;
    margin-bottom: 5px;
    padding-left: 5px;
    color: var(--text);
}

#public-view label[for="operator-dropdown-show-all"] {
    margin-bottom: 0;
}

#public-selects {
    display:flex;
    flex-flow: row;
    gap: 20px;
    padding: 1.5em 4em;
    justify-content: center;
    align-items: baseline;
    position: sticky;
    top: 0;
    background-color: var(--primary-soft);
}

#public-selects>div {
    flex: 1 1 auto;
}

#public-tables {
    display: flex;
    flex-flow: column;
    gap: 20px;
    padding: 2em 4em;
    height: 100%
}

#summary-view {
    height: 60%;
}

#well-lists {
    height: 40%;
}

.operator-search-dropdown {
    max-height: 12rem;
    overflow-y: auto;
    min-width: 100%;
}

.operator-dropdown img {
    width: 0.8em;
}