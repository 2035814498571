.modal-header {
    background-color: var(--primary);
    color: var(--text);
}
  
.add-new-non-activity {
    display: flex;
    flex-flow: row;
    color: var(--primary);
    transition: 0.1s;
}

.add-new-non-activity:hover {
    cursor: pointer;
    background-color: var(--primary-tint);
}
  
.add-new-non-activity:active {
    cursor: pointer;
    background-color: var(--text);
}
  
.add-new-non-activity .plus {
    font-size: 2em;
    line-height: 1.8rem;
    width: fit-content;
    flex: 0 1 auto;
    position: relative;
    top: 0.15em;
}
  
.add-new-non-activity .add-text {
    display: flex;
    flex: 1 1 auto;
    line-height: 2em;
    align-items: center;
}

.modal-row .collapse {
    margin: 5px;
    border: 1px solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    background-color: var(--bkgd-color);
}  

.modal-row .collapse-content>* {
    margin: 5px;
}

.collapse-content {
    display: flex;
    flex-flow: column;
}
  
.modal-row .collapsing {
    margin: 5px;
    border: 1px solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    background-color: var(--bkgd-color);
}
  
.modal-row .number-input-groups {
    display: flex;
    flex-flow: row;
    gap: 10px;
    justify-content: center;
    margin:5px;
}
  
.modal-row .number-input-groups .input-group {
    flex: 0 1 auto;
    width: fit-content;
}
  
.modal-row .activity-number {
    border: 1px solid var(--bs-border-color);
    border-top-right-radius: var(--bs-border-radius);
    border-bottom-right-radius: var(--bs-border-radius);
    padding-left: 5px;
}
  
.modal-row .notes-input-group {
    margin: 5px;
    margin-top: 0px;
    width: calc(100% - 10px);
}

.modal-row .pill {
    font-size: 1em;
    transition: 0.15s;
    background-color: var(--primary-soft);
}
  
.modal-row .pill.disabled {
    background-color: lightgray !important;
    color: gray !important;
    cursor: default !important;
}
  
.modal-row .pill:hover {
    background-color: var(--primary);
    cursor: pointer;
    color: var(--secondary);
}
  
.modal-row .pill:active {
    color: var(--secondary-active);
}

.modal-row-head {
    display: flex;
    flex-direction: row;
    line-height: 1;
}
  
.modal-row-head .head-text {
    position:relative;
    left: 0.1em;
}
  
.modal-row-head .head-caret {
    position:relative;
    top: -.1em;
}

.non-activity-row {
    margin: 0px !important;
    padding: 10px;
    padding-top: 5px;
}
  
.non-activity-row:hover {
    background-color: var(--bkgd-color);
}
  
.non-activity-row:not(:first-child) {
    border-top: 1px solid var(--primary);
}
  
.non-activity-row-inputs {
    display: flex;
    flex-flow: row;
    gap: 10px;
}
  

/* New Drilling Modal */ 

.new-drilling-modal {
    min-width: 40%;
}
  
.new-drilling-modal .modal-body {
    display: flex;
    flex-flow: column;
    gap: 10px;
}


/* New Wells Modal */

.new-wells-modal {
    min-width: 40%;
}
  
.new-wells-modal .modal-body {
    display: flex;
    flex-flow: column;
    gap: 10px;
    max-height: 70vh;
    overflow-y: auto;
}


/* New Report Modal */

.new-report-modal .input-group-text {
    width:6.5em;
    background-color: var(--bkgd-color);
}

/* Inactivity Modal */

#inactivity-modal {
    max-width: 200px;
    margin-top: 300px;
}
  
#inactivity-modal .modal-body {
    text-align: center;
}
  
#inactivity-modal .btn {
    background-color: var(--primary);
    margin-right: 10px;
    width: 60px;
}
  
#inactivity-modal .btn:hover {
    background-color: var(--primary-active);
}


/* Signup Modal */
.signup {
    max-width: 800px !important;
}
  
.signup .input-group-text {
    width:160px;
    background-color: var(--bkgd-color);
}
   
.signup-header {
    padding-left: 5px;
}
  
.employer-dropdown {
    padding:0 !important;
    background-color: var(--bs-primary-bg) !important;
    flex: 1;
    border: 0px !important;
}
  
.employer-dropdown input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.question-button {
    padding: 0 0.2em;
    background-color: var(--primary);
    transition: 0.15s;
}

.question-button:hover {
    background-color: var(--primary-active);
}

.tooltip-inner {
    max-width: 20rem;
}

.tooltip-inner ul {
    margin: 0;
}

.tooltip-valid {
    color: green;
    transition: 0.25s;
}

.tooltip-invalid {
    color: red;
    transition: 0.25s;
}

#request-operator-number-modal {
    margin-top: 3rem;
}

/* Forgot Password Modal */
#verification-code {
    font-size: 1.5em;
    width: 6em;
    text-align: center;
    margin: auto;
}