#login-landing {
    padding: 1em;
}

#back-button {
    text-align: center;
    margin-top: 2em;
}

#back-button button {
    background-color: var(--primary);
    transition: 0.15s;
}

#back-button button:hover {
    background-color: var(--primary-active);
}

.login {
    margin: auto;
    margin-top: 50px;
    padding:10px;
    max-width: 500px;
    width: fit-content;
    background-color: var(--text);
    border-radius: var(--bs-border-radius);
    border: var(--bs-border-width) solid var(--bs-border-color);
}
  
.login .input-group-text {
    width:100px;
    background-color: var(--bkgd-color);
}
  
.login-header {
    border-bottom: var(--bs-border-width) solid var(--bs-border-color);
    margin-bottom: 20px;
    padding-left: 10px;
}

.signup-prompt {
    text-align: right;
    padding-top: 10px;
    padding-right: 10px;
}

.forgot-password-prompt {
    text-align: right;
    padding-right: 10px;
}
  
.inline-link {
    color: var(--accent);
    cursor: pointer;
}
  
.inline-link:hover {
    color: var(--accent-active);
}

#invalid-login {
    text-align: right;
    margin: 0.25em 0.5em;
    color: red;
}