
.content-nav {
    flex: 1 1 auto;
    width: 25rem;
    background-color: var(--primary);
    padding-left: 0px;
    border-right: 1px solid var(--primary-soft);
}
  
.content-nav li {
    margin-left:10px;
    height: 5em;
    padding-left: 2em;
    padding-right: 0px;
    line-height: 1.2;
    border-top-left-radius: 2.5em;
    border-bottom-left-radius: 2.5em;
    vertical-align: center;
}
  
.content-nav:first-child {
    padding-top: 20px;
}
  
.content-nav a:link {
    font-size: 1.5em;
    font-weight: 700;
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--text);
}
  
.content-nav a:hover {
    color: var(--secondary);
}
  
.content-nav a:active {
    color: var(--secondary-active);
}
  
.content-nav a:visited {
    color: var(--text);
}
  
#active-tab {
    background-color: var(--primary-active);
}
  
.nav-select {
    flex: 0 1;
    height: 90px;
    padding: 1rem;
    padding-bottom: 0.5rem;
    padding-top: 0px;
    margin: 0 !important;
}

.nav-select input {
    font-size: 1em;
}
  
.nav-select label {
    font-weight: 600;
    color: var(--text);
    line-height: 1;
    margin-bottom: 5px;
    padding-left: 5px;
}
  
.create-report-button {
    margin: 1rem;
    margin-top: 0px;
    font-weight: 600;
    background-color: var(--accent) !important;
    border: 0px !important;
    font-size: 1em;
}
  
.create-report-button:hover {
    background-color: var(--accent-active) !important;
}
  
.operator-dropdown {
    padding:0 !important;
    background-color: var(--bs-primary-bg) !important;
    flex: 1;
    border: 0px !important;
    width: 100%;
}

label[for='nav-operator'] {
    width: 100%;
    display: flex;
    flex-flow: row;
}

#operator-search-show-all {
    flex: 1 1 auto;
    text-align: right;
}

#operator-search-show-all input[type='checkbox'] {
    margin-top: 0;

}

@media screen and (min-resolution: 120dpi) {
    .content-nav {
        font-size: 0.8rem;
    }

    .content-nav li {
        height: 4em;
        border-top-left-radius: 2em;
        border-bottom-left-radius: 2em;
    }
}