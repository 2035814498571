#mobile-view {
    width: 100vw;
    height: 100vh;

    #mobile-header {
        background-image:url(https://www.blm.gov/themes/custom/bwd8sk/images/main-bg-with-gradient.png);
        background-position: center;
        background-repeat: no-repeat;
        background-color: black;
        width: 100%;
        height: 7rem;
    }
    
    #mobile-header a {
        text-decoration: none;
    }
    
    .logo-text {
        padding-top: 3px;
    }
    
    #logo {
        height: 90%;
    }
    
    #logo-text-top {
        font-size: 0.8rem;
    }
    
    #logo-text-main {
        font-size: 1.6rem;
    }
    
    #mobile-body {
        background-color: var(--bkgd-color);
        color: var(--primary);
        font-size: 1.2em;
        padding: 1em;
        text-align: center;
        height: calc(100vh - 7rem);
        width: 100vw;
    }
    
    #mobile-trees-wrapper {
        margin: auto;
        margin-bottom: 1em;
        width: fit-content;
    }
    
    #mobile-trees {
        width: 10em;
        margin-left: auto;
    }
}
 
