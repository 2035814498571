.well-list {
    display: flex;
    flex-flow: column;
    max-height: 100%;
    flex: 1 1 auto;
    border-radius: 0.5em;
    border-bottom-right-radius: 0;
    border: 1px solid var(--dark-gray);
    background-color: white;
    
}

.well-list-table {
    flex: 1 1 auto;
    overflow-y: scroll;
    scroll-behavior: smooth;
    border-bottom-left-radius: calc(0.5em - 1px);
}

.well-list-title {
    display: flex;
    padding-bottom: 0.25em;
}

.well-list-title>span {
    flex: 1 1 auto;
    font-size: 1.2em;
    padding-left: 0.5em;
    padding-top: 0.5em;
    font-weight: 600;
}

.export:hover {
    background-color: var(--primary-active);
}

.well-list-export:hover {
    background-color: var(--primary-active);
}

.well-list th {
    background-color: var(--secondary);
    position: sticky;
    top: 0;
    flex: 1 1 auto;
    width: fit-content;
}

.waiting-row {
    text-align: center;
}

.well-list-checkbox {
    margin-right: 1em;
    margin-top: auto;
}

.well-list-checkbox input {
    margin-right: 0.25em;
}

.well-list-checkbox label {
    margin-bottom: 0;
    color: var(--text);
    font-weight: 600;
    
}

.sort-controls {
    display: inline-flex;
    flex-flow: column;
    translate: 0 -0.3em;
}

.sort-caret {
    height: 0.6em;
    cursor: pointer;
    opacity: 0.4;
}

.sort-caret.asc {
    transform: rotate(180deg);
}

.sort-caret:hover {
    opacity: 0.8;
}