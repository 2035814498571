.header {
    height: 100%;
    background-image:url('../resources/main-bg-with-gradient.png');
    background-position: center;
    background-repeat: no-repeat;
    background-color: black;
    display: flex;
    flex-flow: row;
}
  
.header a {
    text-decoration: none;
}
  
.logo {
    width: 30rem;
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: row;
}
  
#logo {
    height: 100%;
}
  
.logo-text {
    padding-left: 15px;
    padding-top: 10px;
    font-family: 'Roboto','Helvetica Neue','Arial',sans-serif;
    color: var(--text);
}
  
#logo-text-top {
    font-size: 1rem;
}
  
#logo-text-main {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
}

#title-wrapper {
    display: flex;
    flex-flow: column;
    color: var(--text);
    padding-left: 1.5em;
    cursor: default;
    max-width: 50%;
}

#app-title {
    font-size: 2.5rem;
    line-height: 2.2rem;
    margin-top: auto;
}

#app-subtitle {
    font-size: 1.2rem;
    margin-bottom: 1em;
    padding-left: 0.5em;
}

#account-menu {
    margin-right: 1em;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 1em;
}

.account-badge {
    height: 4rem;
    max-width: 300px;
    width: fit-content;
    padding: 5px;
    padding-left: 20px;
    display: flex;
    flex-flow: row;
    text-align: right;
    background-color: var(--light-black);
    border: 1px solid var(--dark-gray);
    border-radius: 20px;
    cursor: pointer;
}
  
.account-text {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
    color: var(--text);
}
  
#account-name {
    font-weight: 700;
}
  
#account-employer {
    font-size: 0.75em;
}
  
#account-icon {
    height: 100%;
}

#account-menu .dropdown-menu {
    width: 15rem;
    text-align: right;
}

#account-info {
    text-align: left;
    padding-left: 1em;
}

#account-info p {
    margin: 0;
}

#login-signup-link {
    flex: 1 1 auto;
    text-align: right;
    margin-top: auto;
    margin-bottom: 1em;
}

#login-signup-link a {
    margin-right: 1em;
    font-size: 1.2em;
    text-decoration: none;
    color: var(--accent);
}

#login-signup-link a:hover {
    cursor: pointer;
    color: var(--accent-active);
}