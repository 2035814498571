:root {
    --bkgd-color: #F9F8F5;
    --text: #fefefe;
    --primary: #596f31;
    --primary-active: #425224;
    --primary-soft: #818a70;
    --primary-tint: #d2dbc0;
    --secondary: #f4deab;
    --secondary-active: #f8ca60;
    --accent: #ff931b;
    --accent-active: #ce6c00;
    --light-black: #111;
    --dark-gray: #333333;
}

html, body {
    max-height: 100% !important;
    overflow: none !important;
}

@media screen and (min-resolution : 120dpi) {
    body {
        font-size: 0.8rem;
    }
}