.footer {
    height: 100%;
    background-image:url(https://www.blm.gov/themes/custom/bwd8sk/images/main-bg-with-gradient.png);
    background-position: 50% 20%;
    background-repeat: no-repeat;
    background-color: black;
    padding: 8px;
    display: flex;
    flex-flow: row;
    color: var(--text);
}
  
.ars-badge {
    height: 100%;
    width: 500px;
}
  
.ars-badge a {
    height: 100%;
    width: fit-content;
    display: flex;
    flex-flow: row;
    align-items: center;
    color: var(--text);
    text-decoration: none;
    filter: grayscale(1) brightness(30%);
    -webkit-filter: brightness(30%) grayscale(1);
    transition: 0.2s;
}
  
#ars-logo {
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    flex: 0 0 auto;
}
  
.ars-badge a:hover {
    filter: brightness(90%);
    -webkit-filter: brightness(90%);
}
  
.ars-badge-text {
    flex: 0 0 auto;
}

#contact-us {
    margin-left: auto;
    color: var(--text);
}

#contact-us a {
    color: var(--accent);
    text-decoration: none;
    transition: 0.15s;
}

#contact-us a:hover {
    color: var(--accent-active);
}

#documentation-wrapper a {
    text-decoration: none;
    color: var(--accent);
    transition: 0.15s;
}

#documentation-wrapper a:hover {
    color: var(--accent-active)
}